export default  {
lang:{

  hardwareTotalDetail:'Ausrüstungs-Tutorial',
  softwareTotalDetail:'Software-Tutorial',
  deviceType:'Drucker Typ-Nr.',
  clickToVisitVideo:'click to visit video',
  clickToVisitManual:'click to visit manual',
  bluetoothConnection:'Bluetooth Connection',
  newLabels:'New labels',
  selectLabels:'Select Labels',
  saveLabels:'Save Labels',
  setGrayscale:'Set Grayscale',
  editingLabels:'Editing Labels',
  multiplePrinting:'Multiple Printing',
  insert1Dcode:'Insert 1D code',
  insert2Dcode:'Insert 2D code',
  textEditing: 'Text editing',
  borderMaterial: 'Border material',
  printSetup: 'Print setup',
  saveEdit: 'Save Edit',
  insertPicture:'Insert picture',
  printConcentration:'Print concentration',
  adjustText:'Adjust  text',
  excelImport:'Excel import',
  incrementalData:'Incremental data',
  textAlignment:'Text alignment',
  selectCloudTemplate:'Select cloud template',
  scanCodeAndInsert:'Scan code and insert',
  ok:'fertig stellen',
  cancel:'abbrechen',
  tableSetting:'tableSetting',
  imageRecognition:'Image recognition',
  paperLoadingTutorial:'Paper loading tutorial',
  manual:'Manual',
  continuousUpdate: 'The video continues to update',
  networkAnomaly: 'Network anomalies',
  loading: 'Loading...',
  faq: 'Q&A'
}
}
