var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "loadBox" },
    [
      _c(
        "van-loading",
        { attrs: { color: "#FFFFFF", vertical: "", size: "3rem" } },
        [_vm._v(_vm._s(_vm.$t("lang.loading")))]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-403e29f6", { render: render, staticRenderFns: staticRenderFns })
  }
}