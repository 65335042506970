import Vue from "vue";
import Vuex from 'vuex';
Vue.use(Vuex)
export const JSONDATA_SETTER = "JSONDATA_SETTER";//这样外面就可以使用它来发送事件
export const USER="USER";
export const DIARY="DIARY";

export const DIARY_BOOK="DIARY_BOOK";
export const CONTENT="CONTENT";
export const CATALOG="CATALOG";
export const IS_SHOW="IS_SHOW";
export const SCROLLING="SCROLLING";
export const PRODUCT_TYPE="PRODUCT_TYPE";
export const HARDWARE_IMG="HARDWARE_IMG";

const state = {
  //数据源
  jsonData: 'test',
  diary:'',
  user:'',

  diaryBook:{},
  content:{},
  catalog:{},
  isShow:false,
  scrolling:false,
  productType:'',
  hardwareImg:''
};
const mutations = {
  [SCROLLING](state,payload){
    state.scrolling=payload.scrolling;
  },
  [IS_SHOW](state,payload){
    state.isShow=payload.isShow;
  },
  [DIARY_BOOK](state,payload){
    state.diaryBook=payload.diaryBook;
  },
  [CONTENT](state,payload){
    state.content=payload.content;
  },
  [CATALOG](state,payload){
    state.catalog=payload.catalog;
  },
  [PRODUCT_TYPE](state,payload){
    state.productType=payload;
  },
  [HARDWARE_IMG](state,payload){
    state.hardwareImg=payload;
  },
  //其实相当于setter,但同步
//这里用store.commit('方法',payload)去触发状态更新,类似于事件总线
  [JSONDATA_SETTER] (state, payload){
    // console.table(payload.data);
    state.jsonData = payload.data;
  },
  [USER](state,payload){
    state.user=payload.user;
  },
  [DIARY](state,payload){
    state.diary=payload.diary;
  }

};

const getters = {
  scrolling:function (state) {
    return state.scrolling;
  },
  isShow:function (state) {
    return state.isShow;
  },
  background: function (state) {
  return state.jsonData.background;
  },
  brush: function (state) {
  return state.jsonData.brush;
  },
  canvasSize: function (state) {
  return state.jsonData.canvasSize;
  },
  content: function (state) {
  return state.content;
  },
  catalog: function (state) {
  return state.catalog;
  },
  diaryBook: function (state) {
  return state.diaryBook;
  },
  page: function (state) {
  return state.jsonData.page;
  },
  diary: function (state) {
  return state.diary;
  },
  user: function (state) {
  return state.user;
  },
  productType: function (state) {
  return state.productType;
  },
  hardwareImg: function (state) {
  return state.hardwareImg;
  },

};
const actions = {
  //异步setter

};

export default new Vuex.Store({
  state, mutations, getters, actions
})
