export default  {
  lang:{
    hardwareTotalDetail:'軟件使用說明',
    softwareTotalDetail:'硬件使用說明',
    clickToVisitVideo:'點擊查看視頻',
    clickToVisitManual:'點擊查看說明書',
    deviceType:'打印機型號',
    bluetoothConnection:'藍牙連接',
    newLabels:'新建標簽',
    selectLabels:'選擇標簽',
    saveLabels:'保存標簽',
    editingLabels:'編輯標簽',
    multiplePrinting:'多分打印',
    insert1Dcode:'插入一維碼',
    insert2Dcode:'插入二維碼',
    insertPicture:'插入圖片',
    textEditing: '文字編輯',
    borderMaterial: '邊框素材',
    printSetup: '打印設置',
    saveEdit: '保存編輯',
    printConcentration:'打印濃度',
    setGrayscale:'設置濃度',
    adjustText:'調整文本大小位置',
    excelImport:'Excel導入批量打印',
    incrementalData:'遞變數據',
    textAlignment:'文本對齊',
    selectCloudTemplate:'選擇模板',
    scanCodeAndInsert:'掃碼插入',
    ok:'確認',
    cancel:'取消',
    tableSetting:'表格設置',
    imageRecognition:'圖片識別',
    paperLoadingTutorial:'裝紙教程',
    manual:'說明書',
    continuousUpdate: '視頻持續更新中...~',
    networkAnomaly: '網絡異常',
    loading: '加載中...',
    faq: '常見問題'
  }
}