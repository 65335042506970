var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "instructionsList" },
    [
      _c("h3", [_vm._v("选择打印机后查看说明书")]),
      _vm._v(" "),
      _c("p", [_vm._v("Select the printer and view instruction")]),
      _vm._v(" "),
      _c(
        "van-row",
        { staticClass: "listContainer" },
        _vm._l(_vm.listData, function(item, index) {
          return _c(
            "van-col",
            { key: index, staticClass: "listItem", attrs: { span: "12" } },
            [
              _c("img", {
                attrs: { src: item.iconUrl || _vm.defaultImg },
                on: {
                  click: function($event) {
                    return _vm.turnToDetail(item)
                  }
                }
              }),
              _vm._v(" "),
              _c("p", [
                _c(
                  "span",
                  {
                    on: {
                      click: function($event) {
                        return _vm.turnToDetail(item)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.deviceType))]
                )
              ])
            ]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("load", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ]
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6291d218", { render: render, staticRenderFns: staticRenderFns })
  }
}