var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "instructionsDetail" },
    [
      _c(
        "div",
        { staticClass: "detailHeader" },
        [
          _c("span", { staticClass: "backIcon", on: { click: _vm.toBack } }, [
            _c("img", { attrs: { src: _vm.toBackIcon, width: "24px" } })
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "detailTitle" }, [
            _vm._v(_vm._s(_vm.deviceType))
          ]),
          _vm._v(" "),
          _c(
            "van-button",
            {
              attrs: { round: "", type: "info" },
              on: { click: _vm.showLanguage }
            },
            [
              _vm._v(_vm._s(_vm.langItem.originName || "多语言")),
              _c("img", { attrs: { src: _vm.buttonDown } })
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { ref: "detailCcontainerRef", staticClass: "detailCcontainer" },
        [
          _vm.detailInfo.fileUrl
            ? _c("img", { attrs: { src: _vm.detailInfo.fileUrl } })
            : _c(
                "h3",
                { staticStyle: { "text-align": "center", color: "#7D8799" } },
                [_vm._v("\n      暂无内容\n    ")]
              )
        ]
      ),
      _vm._v(" "),
      _c(
        "van-popup",
        {
          style: { height: "41%" },
          attrs: { round: "", position: "bottom" },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("div", { staticClass: "popupHeader" }, [
            _c(
              "span",
              { staticClass: "closeIcon", on: { click: _vm.closePop } },
              [_c("img", { attrs: { src: _vm.closeIcon, width: "24px" } })]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "headerTitle" }, [_vm._v("多语言")]),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "yesIcon", on: { click: _vm.changeLanguage } },
              [_c("img", { attrs: { src: _vm.yesIcon, width: "24px" } })]
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "popupContent" },
            [
              _c("van-picker", {
                attrs: {
                  columns: _vm.columns,
                  "show-toolbar": false,
                  "default-index": _vm.selectIndex
                },
                on: { change: _vm.changePicker }
              })
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("load", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading || _vm.changeLoading,
            expression: "loading || changeLoading"
          }
        ]
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f008ff0a", { render: render, staticRenderFns: staticRenderFns })
  }
}