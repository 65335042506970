// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import App from './App'
import router from './router'
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
Vue.config.productionTip = false;
// Vue.use(ElementUI);
Vue.use(Vant);
import store from "@/vuex/store";
import '@/app.css';
import VueI18n from 'vue-i18n'
// import i18n from './lang/i18n'
import zh from "./lang/zh";
import hk from "./lang/hk";
import tw from "./lang/hk";
import en from "./lang/en";
import ko from "./lang/ko";
import il from "./lang/il";
import fr from "./lang/fr";
import de from "./lang/de";
import pt from "./lang/pt";
import es from "./lang/es";
import jp from "./lang/jp";
import pl from "./lang/pl";
import it from "./lang/it";
import In from "./lang/in";
import thai from "./lang/thai";
import malay from "./lang/malay";
import cz from "./lang/cz";
import tr from "./lang/tr";
import ru from "./lang/ru";
import vi from "./lang/vi";
Vue.use(VueI18n)
// import $ from 'jquery'
/* eslint-disable no-new */
const messages = {
  /** 中文 */ 
  'zh-cn': { ...zh },
  'zh-CN': { ...zh },
  /** 英语 */ 
  'en-us': { ...en },
  'en-US': { ...en },
  /** 韩国 */ 
  'ko-kr': { ...ko },
  'ko-KR': { ...ko },
  /** 香港 */ 
  'zh-hk': { ...hk },
  'zh-HK': { ...hk },
  /** 台湾 */ 
  'zh-tw': { ...tw },
  'zh-TW': { ...tw },
  /** 阿拉伯语 */ 
  'ar-il': { ...il },
  'ar-IL': { ...il },
  /** 法语 */ 
  'fr-fr': { ...fr },
  'fr-FR': { ...fr },
  /** 德语 */ 
  'de-de': { ...de },
  'de-DE': { ...de },
  /** 葡萄牙 */ 
  'pt-pt': { ...pt },
  'pt-PT': { ...pt },
  /** 西班牙 */ 
  'es-es': { ...es },
  'es-ES': { ...es },
  /** 日语 */ 
  'ja-jp': { ...jp },
  'ja-JP': { ...jp },
  /** 波兰 */ 
  'pl-pl': { ...pl },
  'pl-PL': { ...pl },
  /** 意大利 */ 
  'it-it': { ...it },
  'it-IT': { ...it },
  /** 印尼 */ 
  'in-id': { ...In },
  'in-ID': { ...In },
  /** 泰语 */ 
  'th-th': { ...thai },
  'th-TH': { ...thai },
  /** 马来语 */ 
  'ms-my': { ...malay },
  'ms-MY': { ...malay },
  /** 捷克语 */ 
  'cs-cz': { ...cz },
  'cs-CZ': { ...cz },
  /** 土耳其语 */ 
  'tr-tr': { ...tr },
  'tr-TR': { ...tr },
  /** 俄语 */ 
  'ru-ru': { ...ru },
  'ru-RU': { ...ru },
  /** 越南语 */ 
  'vi-vn': { ...vi },
  'vi-VN': { ...vi }
}
const i18n = new VueI18n({
  locale: 'en',
  messages
})
new Vue({
  el: '#app',
  i18n,
  router, store, //todo 这里是要配置store的
  // components: { App },
  // template: '<App/>'
  render: h => h(App)
}).$mount("#app");
