<template >
  <div id="app" style="width: 100%;min-height: 100%;position: absolute">
    <!--<router-link to="./labelmaster/tutorial">首页</router-link>-->
    <!--<router-link to="./labelmaster/tutorial/test01" append>Hi页面</router-link>-->
    <router-view v-if="isWinxinFlag"/>
    <div id="TipsID" v-else class="TipsPage">
      <img class="arrowing" :src="arrowing" alt="">
        <div class="hreadBox" style="height:170px;">
            <!-- <div class="logoBox">
                <img width="80" height="80" :src="quyinLOGO" alt="none">
            </div>
            <p>印先森</p> -->
        </div>
        <div class="conterBox"> 
          <p style="text-indent:-42px;">1，点击右上角“...”</p>
          <p>2，选择“在浏览器打开”</p>
        </div>
    </div>
  </div>
</template>

<script>
//  import $  from 'turn.js/node_modules/jquery/dist/jquery';
  //  import turn from 'turn.js';
  import axios from 'axios'
  import {DIARY_BOOK, CONTENT, CATALOG} from "@/vuex/store";
  import arrowing from './assets/arrowing.png'
  import quyinLOGO from './assets/quyinLOGO.png'
  
  //  import {mu-raised-button} from 'muse-ui';
//  import store from "@/vuex/store";
//  import {mapState, mapActions, mapMutations, mapGetters} from 'vuex';
//  import {IS_SHOW} from "@/vuex/store";
// 测试提交
  export default {
    name: 'App',
    created: function () {
      var ua = navigator.userAgent.toLowerCase();
      var isWinxin = ua.indexOf('micromessenger') != -1;
      if(isWinxin){
        this.isWinxinFlag = false;
        window.document.title = "提示";
      }else{
        this.isWinxinFlag = true;
      }
      console.log("app created")
    },
    updated:function () {
      console.log('app updated')
    },
    mounted: function () {
      console.log('app mounted')
    },
    methods: {
    },
    data(){
      return {
        isWinxinFlag:true,
        arrowing,
        quyinLOGO
      }
    }

  }
</script>

<style>
  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    background-color: #f7f5f3;
  }
  .TipsPage{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color:#33343D;
}
.TipsPage .arrowing{
  position:absolute;
  top: 20px;
  right:10px;
  width: 129px;
  height: 177px;
}
.logoBox{
  display: flex;
  justify-content: center;
  margin-top: 60px;
  width: 100%;
  height: 80px;
}
.logoBox img{
  width:80px;
  height:80px;
}
.hreadBox p{
  font-size: 20px;
  font-family: PingFang SC;
  color: #04040f;
  margin: 10px 0;
  text-align: center;
}
.conterBox{
  width: 80%;
  font-size: 18px;
  margin: 30px auto;
  color: #FFE75E ;
  border: 2px solid #FFE75E ;
}
.conterBox p {
  text-align: center;
}

</style>
