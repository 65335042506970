
export default  {
  lang:{
    hardwareTotalDetail:'硬件使用说明',
    softwareTotalDetail:'软件使用说明',
    clickToVisitVideo:'点击查看视频',
    clickToVisitManual:'点击查看说明书',
    deviceType:'打印机型号',
    bluetoothConnection:'蓝牙连接',
    newLabels:'新建标签',
    selectLabels:'选择标签',
    saveLabels:'保存标签',
    editingLabels:'编辑标签',
    multiplePrinting:'多分打印',
    insert1Dcode:'插入一维码',
    insert2Dcode:'插入二维码',
    insertPicture:'插入图片',
    textEditing: '文字编辑',
    borderMaterial: '边框素材',
    printSetup: '打印设置',
    saveEdit: '保存编辑',
    printConcentration:'打印浓度',
    setGrayscale:'设置浓度',
    adjustText:'调整文本大小位置',
    excelImport:'Excel导入批量打印',
    incrementalData:'递变数据',
    textAlignment:'文本对齐',
    selectCloudTemplate:'选择模板',
    scanCodeAndInsert:'扫码插入',
    ok:'确认',
    cancel:'取消',
    tableSetting:'表格设置',
    imageRecognition:'图片识别',
    paperLoadingTutorial:'装纸教程',
    manual:'说明书',
    continuousUpdate: '更多视频持续更新中~',
    networkAnomaly: '网络连接异常',
    loading: '加载中',
    faq: '常见问题'
  }
}
