import Vue from 'vue'
import Router from 'vue-router'
// import MusicBook from '@/components/MusicBook'
import Test01 from "../components/Test01";
import Tutorial from "../components/labelmaster/tutorial/Tutorial";
// import DiaryBook from '@/components/DiaryBook'
import Instructions from '@/components/Instructions/index.vue'; //说明书列表
import InstructDetail from '@/components/Instructions/detail.vue'; //说明书详情

Vue.use(Router);
export default new Router({
  // mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Tutorial',
      component: Tutorial
    },
    // {
    //   path:'/labelmaster/tutorial/test01',
    //   name:'Test01',
    //   component:Test01
    // }
    // {
    //   path: '/diaryBook/:id',
    //   name: 'MusicBook',
    //   component: MusicBook
    // },
    {
      path: '/instructions',
      name: 'instructions',
      component: Instructions
    },
    {
      path: '/instructDetail/:deviceType/:id',
      name: 'instructDetail',
      component: InstructDetail
    }
  ]
})
