var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [_vm._v(_vm._s(_vm.message))]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _vm.isTrue ? _c("div", [_vm._v("u see see u")]) : _vm._e(),
    _vm._v(" "),
    _c("div", [
      _c(
        "ol",
        _vm._l(_vm.todos, function(todo) {
          return _c("li", [
            _vm._v("\n          " + _vm._s(todo.text) + "\n        ")
          ])
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c("div", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.userInput,
            expression: "userInput"
          }
        ],
        domProps: { value: _vm.userInput },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.userInput = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("span", [_vm._v("you input ")]),
      _c("span", [_vm._v(_vm._s(_vm.userInput))])
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("div", [
      _c("button", { attrs: { id: "btn01" }, on: { click: _vm.mfk } }, [
        _vm._v("click u")
      ])
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c(
      "div",
      _vm._l(_vm.todos, function(item) {
        return _c("todo-item", { key: item.id, attrs: { todo: item } })
      }),
      1
    ),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("div", [
      _c("a", { attrs: { url: _vm.urlData } }, [_vm._v("url test 02")]),
      _c("br"),
      _vm._v(" "),
      _c("a", { attrs: { url: _vm.urlData } }, [_vm._v("url test 02 -v2")]),
      _vm._v(" "),
      _c("a", { on: _vm._d({}, [_vm.eventName, _vm.whenOnFocus]) }, [
        _vm._v("test 03")
      ]),
      _vm._v(" "),
      _c("a", { on: { click: _vm.whenOnFocus } }, [_vm._v("test 03-v2")])
    ]),
    _vm._v(" "),
    _c("div", [
      _c("div", [_vm._v("计算属性")]),
      _vm._v(" "),
      _c("div", [
        _c("div", [
          _c("p", [_vm._v('Original message: "' + _vm._s(_vm.message) + '"')]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              'Computed reversed message: "' + _vm._s(_vm.reversedMessage) + '"'
            )
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("br"),
    _vm._v(" "),
    _c("div", [
      _c("div", [_vm._v("watch ")]),
      _vm._v(" "),
      _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.pp,
              expression: "pp"
            }
          ],
          domProps: { value: _vm.pp },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.pp = $event.target.value
            }
          }
        })
      ])
    ]),
    _vm._v(" "),
    _c("div", [
      _c("h1", [_vm._v("class and style")]),
      _vm._v(" "),
      _c("div", [
        _c("div", [
          _c("span", { class: { cc: _vm.isActive } }, [_vm._v(" class test")])
        ]),
        _vm._v(" "),
        _c("div", [
          _c("span", { class: _vm.classObj }, [_vm._v(" class test 2")])
        ]),
        _vm._v(" "),
        _c("div", [
          _c("span", { class: _vm.classObjByCal }, [_vm._v(" class test 3")])
        ]),
        _vm._v(" "),
        _c("div", [
          _c("span", { style: _vm.styleObj }, [_vm._v(" style test 01")])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", [
      _c("h1", [_vm._v("v-if and v-else")]),
      _vm._v(" "),
      _vm.isShow
        ? _c("div", [_vm._v("u see see u")])
        : _c("div", [_vm._v("i don't see u")])
    ]),
    _vm._v(" "),
    _c("div", [
      _c("h1", [_vm._v("event deal method")]),
      _vm._v(" "),
      _c("div", [
        _c(
          "button",
          {
            on: {
              click: function($event) {
                return _vm.whenOnClick("msgggg", $event)
              }
            }
          },
          [_vm._v(" hit m e hhh")]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("ComponentA", {
            attrs: { id: "123132", msg: _vm.msg, title: _vm.bind_titles }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("span", [_vm._v("component a test")])])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1382d2c3", { render: render, staticRenderFns: staticRenderFns })
  }
}