<template>
  <div class="loadBox">
    <van-loading color="#FFFFFF" vertical size="3rem">{{$t('lang.loading')}}</van-loading>
  </div>
</template>

<script>
export default {
  name: 'load'
}
</script>

<style>
.loadBox{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background: rgba(37, 42, 51, 0.8);;
  width: 6rem;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  font-weight: 400;
  padding: 5px;
}
</style>