var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        {
          on: {
            click: function($event) {
              _vm.showSelect = true
            }
          }
        },
        [
          _c("van-nav-bar", {
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _c("div", { staticClass: "headTitle" }, [
                      _c("h1", [_vm._v(_vm._s(_vm.modelName))]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.$t("lang.deviceType")))])
                    ])
                  ]
                },
                proxy: true
              },
              {
                key: "right",
                fn: function() {
                  return [
                    _c("van-icon", {
                      attrs: { name: "arrow", color: "#252A33" }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "van-sticky",
        [
          _c(
            "van-tabs",
            {
              attrs: {
                color: _vm.themeColor,
                "title-active-color": _vm.themeColor,
                "title-inactive-color": "#222222",
                ellipsis: false
              },
              on: { click: _vm.changeTabs },
              model: {
                value: _vm.showTab,
                callback: function($$v) {
                  _vm.showTab = $$v
                },
                expression: "showTab"
              }
            },
            _vm._l(_vm.tabs, function(item, index) {
              return _c("van-tab", { key: index, attrs: { title: item } })
            }),
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      !_vm.showVideo && !_vm.networkError
        ? _c("div", { staticClass: "tabContent" }, [
            _vm.showTab === 0
              ? _c(
                  "div",
                  [
                    _vm._l(_vm.instructionsObj, function(item, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _c("p", { staticClass: "title" }, [
                            _vm._v(_vm._s(item.name))
                          ]),
                          _vm._v(" "),
                          _c(
                            "van-grid",
                            {
                              attrs: {
                                border: false,
                                "column-num": 2,
                                gutter: 16
                              }
                            },
                            _vm._l(item.children, function(itemL, indexL) {
                              return _c(
                                "van-grid-item",
                                {
                                  key: indexL,
                                  on: {
                                    click: function($event) {
                                      return _vm.openVideo(itemL.fileUrl)
                                    }
                                  }
                                },
                                [
                                  _c("div", { staticClass: "Box" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "van-multi-ellipsis--l2 itemTitle"
                                      },
                                      [_vm._v(_vm._s(itemL.name))]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "foot" },
                                      [
                                        _c("van-icon", {
                                          attrs: {
                                            name: "play-circle",
                                            color: _vm.themeColor
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ])
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "tip" }, [
                      _vm._v(_vm._s(_vm.$t("lang.continuousUpdate")))
                    ])
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showTab === 1
              ? _c(
                  "div",
                  [
                    _vm._l(_vm.hardwareVideo, function(item, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "videoBox",
                          on: {
                            click: function($event) {
                              return _vm.openVideo(item.fileUrl)
                            }
                          }
                        },
                        [
                          _c("span", [_vm._v(_vm._s(item.name))]),
                          _vm._v(" "),
                          _c("van-icon", {
                            attrs: {
                              name: "play-circle",
                              color: _vm.themeColor,
                              size: "2.5rem"
                            }
                          })
                        ],
                        1
                      )
                    }),
                    _vm._v(" "),
                    _vm.hardwareImg.length !== 0
                      ? _c(
                          "div",
                          { staticClass: "instructions" },
                          [
                            _c("p", { staticClass: "title" }, [
                              _vm._v(_vm._s(_vm.$t("lang.manual")))
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.hardwareImg, function(item, index) {
                              return _c("img", {
                                key: index,
                                staticStyle: {
                                  width: "100%",
                                  display: "block"
                                },
                                attrs: { src: item.fileUrl }
                              })
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showTab === 2
              ? _c(
                  "div",
                  [
                    _c(
                      "van-collapse",
                      {
                        attrs: { accordion: "" },
                        model: {
                          value: _vm.faqName,
                          callback: function($$v) {
                            _vm.faqName = $$v
                          },
                          expression: "faqName"
                        }
                      },
                      _vm._l(_vm.faqList, function(item, index) {
                        return _c(
                          "van-collapse-item",
                          {
                            key: index,
                            attrs: {
                              title: item.name,
                              name: item.name,
                              "title-class": "faqTitle"
                            }
                          },
                          [
                            _c("img", {
                              staticStyle: { width: "100%", display: "block" },
                              attrs: { src: item.fileUrl }
                            })
                          ]
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.networkError
        ? _c("div", { staticClass: "loadErrorBox" }, [
            _c("img", {
              staticClass: "networkImg",
              attrs: { src: _vm.networkErr }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "tipBox" }, [
              _c("span", [_vm._v(_vm._s(_vm.$t("lang.networkAnomaly")))]),
              _vm._v(" "),
              _c("img", {
                attrs: { src: _vm.refresh },
                on: { click: _vm.getDatas }
              })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showVideo
        ? _c("div", { staticClass: "videoBox" }, [
            _c(
              "div",
              { staticClass: "btnBox" },
              [
                _c("van-icon", {
                  attrs: { name: "clear", color: "#ee0a24", size: "2rem" },
                  on: {
                    click: function($event) {
                      _vm.showVideo = false
                    }
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "video",
              {
                staticStyle: { display: "block", margin: "0 auto" },
                attrs: { width: "80%", controls: "", autoplay: "" }
              },
              [
                _c("source", {
                  attrs: { src: _vm.videoUrl, type: "video/mp4" }
                })
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "van-popup",
        {
          attrs: { position: "bottom" },
          model: {
            value: _vm.showSelect,
            callback: function($$v) {
              _vm.showSelect = $$v
            },
            expression: "showSelect"
          }
        },
        [
          _c("div", { staticClass: "popupTitle" }, [
            _c("div", { staticClass: "btnBox" }, [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.close },
                on: {
                  click: function($event) {
                    _vm.showSelect = false
                  }
                }
              })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("lang.deviceType")))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "btnBox" }, [
              _c("img", {
                staticStyle: { width: "100%" },
                attrs: { src: _vm.sure },
                on: { click: _vm.onConfirm }
              })
            ])
          ]),
          _vm._v(" "),
          _c("van-picker", {
            ref: "pickerRef",
            attrs: { columns: _vm.columns },
            on: { change: _vm.changeSelect }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("load", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showLoad,
            expression: "showLoad"
          }
        ]
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0f4824b6", { render: render, staticRenderFns: staticRenderFns })
  }
}