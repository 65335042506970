import axios from 'axios'
// 创建axios实例
const service = axios.create({
  baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 1200000 // 请求超时时间
})
console.log(process.env.BASE_API);

// request拦截器
service.interceptors.request.use(
  config => {
    // if (getToken()) {
    //   config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
    // }
    if(!config.headers['Content-Type']){
      config.headers['Content-Type'] = 'application/json'
    }
    if(document.domain === "h5.aimotech.cn"){
      config.baseURL = config.baseURL.replace("api.labelmaster.qu-in.com","api.labelmaster.aimotech.cn")
    }else if(document.domain === "h5.qu-in.club"){
      config.baseURL = config.baseURL.replace("api.labelmaster.qu-in.com","api.qu-in.club")
    }else if(document.domain === "h5.qu-in.top"){
      config.baseURL = config.baseURL.replace("api.labelmaster.qu-in.com","api.qu-in.top")
    }else if(document.domain === "h5-test.saas.qu-in.top"){ //新后台测试环境
      config.baseURL = config.baseURL.replace("api-test.labelmaster.qu-in.com","api-test.saas.qu-in.top/test/lifemaster")
    }else if(document.domain === "h5.saas.qu-in.top"){  //新后台正式环境
      config.baseURL = config.baseURL.replace("api.labelmaster.qu-in.com","api.lifemaster.qu-in.top/lifemaster")
    }
    return config
  },
  error => {
    // Do something with request error
    Promise.reject(error)
  }
)

// response 拦截器
service.interceptors.response.use(
  response => {
    const code = response.status
    if (code < 200 || code > 300) {
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
)
export default service
