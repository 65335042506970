import request from '@/utils/request'

export function getTypeList() {
  return request({
    url: 'api/tutorial/getTypeList',
    method: 'get',
  })
}

export function getType(params) {
  return request({
    url: 'api/tutorial/getType',
    method: 'get',
    params
  })
}

export function getDatas(params) {
  return request({
    url: 'api/tutorial/getDatas',
    method: 'get',
    params
  })
}
