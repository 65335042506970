import request from '@/utils/request';

//根据应用和系列获取机型
export function getDevices(params) {
  return request({
    url: 'api/manual/getDevices',
    method: 'get',
    params
  })
};

//根据机型获取语言和说明书
export function getManuals(params) {
  return request({
    url: 'api/manual/getManuals',
    method: 'get',
    params
  })
};
