var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: {
        width: "100%",
        "min-height": "100%",
        position: "absolute"
      },
      attrs: { id: "app" }
    },
    [
      _vm.isWinxinFlag
        ? _c("router-view")
        : _c("div", { staticClass: "TipsPage", attrs: { id: "TipsID" } }, [
            _c("img", {
              staticClass: "arrowing",
              attrs: { src: _vm.arrowing, alt: "" }
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "hreadBox",
              staticStyle: { height: "170px" }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "conterBox" }, [
              _c("p", { staticStyle: { "text-indent": "-42px" } }, [
                _vm._v("1，点击右上角“...”")
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("2，选择“在浏览器打开”")])
            ])
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7ba5bd90", { render: render, staticRenderFns: staticRenderFns })
  }
}