<!--
@create date 2021-10-23
@desc 小标打印机说明书详情
-->

<template>
  <div class="instructionsDetail">
    <div class="detailHeader">
      <span class="backIcon" @click="toBack"><img :src="toBackIcon" width="24px" /></span>
      <span class="detailTitle">{{ deviceType }}</span>
      <van-button round type="info" @click="showLanguage"
        >{{ langItem.originName || "多语言" }}<img :src="buttonDown"
      /></van-button>
    </div>
    <div class="detailCcontainer" ref="detailCcontainerRef">
      <img :src="detailInfo.fileUrl" v-if="detailInfo.fileUrl" />
      <h3 style="text-align: center;color: #7D8799;" v-else>
        暂无内容
      </h3>
    </div>
    <van-popup
      v-model="visible"
      round
      position="bottom"
      :style="{ height: '41%' }"
    >
      <div class="popupHeader">
        <span class="closeIcon" @click="closePop"><img :src="closeIcon" width="24px" /></span>
        <span class="headerTitle">多语言</span>
        <span class="yesIcon" @click="changeLanguage"
          ><img :src="yesIcon" width="24px"
        /></span>
      </div>
      <div class="popupContent">
        <van-picker
          :columns="columns"
          @change="changePicker"
          :show-toolbar="false"
          :default-index="selectIndex"
        />
      </div>
    </van-popup>
    <load v-show="loading || changeLoading"></load>
  </div>
</template>

<script>
import { getManuals } from "@/api/instructions";
import Load from "@/components/load.vue";
import buttonDown from "@/assets/buttonDown.png";
import toBackIcon from "@/assets/toBackIcon.png";
import closeIcon from "@/assets/closeIcon.png";
import yesIcon from "@/assets/yesIcon.png";

export default {
  name: "InstructDetail",
  data() {
    return {
      buttonDown,
      toBackIcon,
      closeIcon,
      yesIcon,
      loading: false,
      changeLoading: false,
      deviceType: "",
      visible: false, //语言选择

      languages: [],
      columns: [],
      manuals: [],
      selectIndex: 0,
      detailInfo: {},
      langItem: {} //当前选择的语言项
    };
  },
  components: { Load },
  mounted() {
    const params = this.$route.params || {};
    this.getData(params && params.id);
    this.deviceType = params.deviceType;
  },
  methods: {
    getData(id) {
      if (!id) return;
      this.loading = true;
      getManuals({ id })
        .then(res => {
          if (res && res.status === 2000) {
            const data = res.data || {};
            this.languages = data.langs || [];
            this.columns = this.languages.map(v => v.originName);
            this.manuals = data.manuals || [];
            if (this.languages.length > 0) {
              this.getDetail(this.languages[0].id);
              this.langItem = this.languages[0] || {};
            }
          }
        })
        .finally(err => {
          this.loading = false;
        });
    },
    getDetail(langId) {
      if (!langId) return;
      const { manuals } = this;
      this.detailInfo = manuals.find(v => v.langId === langId) || {};
      this.changeLoading = true;
      setTimeout(() => {
        this.changeLoading = false;
        const { detailCcontainerRef } = this.$refs;
        detailCcontainerRef.scrollTop = 0;
      }, 300);
    },
    toBack() {
      window.history.go(-1);
    },
    showLanguage() {
      this.visible = true;
      const { langItem, languages } = this;
      //再次打开弹出层，要重置弹出选项index
      languages.forEach((v, i) => {
        if (v.id === langItem.id) this.selectIndex = i;
      });
    },
    closePop() {
      this.visible = false;
    },
    changePicker(_, value, index) {
      this.selectIndex = index;
    },
    changeLanguage() {
      this.visible = false;
      const { selectIndex, languages } = this;
      this.langItem = languages[selectIndex] || {};
      this.getDetail(this.langItem.id);
    }
  }
};
</script>

<style src="./style.css" />
