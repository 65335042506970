<template>
  <div>
      <span>{{id}}--{{msg}}---{{title}}</span>
  </div>
</template>
<script>
  import Vue from 'vue';
  export default {
    data(){
      // msg:'this is a component a'
      return {
        // msgcc:'this is a component a'
      }
    },
    // props:['title','id','value']
    props:{
      title:String,
      id:String,
      msg:String
    }
  };
</script>
