<template>
  <div>
    <div>{{message}}</div>
    <br>
    <div v-if="isTrue">u see see u</div>
    <div>
      <ol>
          <li v-for="todo in todos">
            {{todo.text}}
          </li>
      </ol>
    </div>
    <div>
  <input v-model="userInput"/>
      <br>
      <span>you input </span><span >{{userInput}}</span>
    </div>
    <br>

    <div>
      <button id="btn01" v-on:click="mfk">click u</button>
    </div>
    <br>
    <div>
      <todo-item v-for="item in todos" v-bind:todo="item" v-bind:key="item.id">
      </todo-item>
    </div>
    <br>
    <div>
      <!--<a v-bind:[attrName]='url'>url test 01</a><br/>-->
      <a v-bind:url='urlData'>url test 02</a><br/>
      <a :url="urlData">url test 02 -v2</a>
      <a v-on:[eventName]="whenOnFocus">test 03</a>
      <a @click="whenOnFocus">test 03-v2</a>


    </div>
    <div>
      <div>计算属性</div>
      <div>
        <div >
          <p>Original message: "{{ message }}"</p>
          <p>Computed reversed message: "{{ reversedMessage }}"</p>
        </div>
      </div>
    </div>
    <br/>
    <div>
      <div>watch </div>
      <div>
        <input v-model="pp"/>
      </div>
    </div>
    <div>
      <h1>class and style</h1>
      <div>
          <div><span v-bind:class="{cc:isActive}"> class test</span></div>
        <div><span :class="classObj"> class test 2</span></div>
        <div><span :class="classObjByCal"> class test 3</span></div>
        <div><span :style="styleObj"> style test 01</span></div>
      </div>
    </div>
    <div>
      <h1>v-if and v-else</h1>
      <div v-if="isShow">u see see u</div>
      <div v-else>i don't see u</div>
    </div>
    <div>
      <h1>event deal method</h1>
      <div>
        <button @click="whenOnClick('msgggg',$event)"> hit m e hhh</button>
      </div>
    </div>
    <div>
      <div><span>component a test</span></div>
      <div>
        <ComponentA id="123132" :msg="msg" :title="bind_titles"></ComponentA>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import ComponentA from "./ComponentA";
  Vue.component("todo-item",{
    props:['todo'],
    template:'<li>{{todo.text}}</li>'
  })
  export default {
    components: {ComponentA},
    props:[],
    data() {
      return {
        msg:'fds',
        bind_titles:'hhe bind title  who your dady',
        isShow:false,
        styleObj:{
          backgroundColor:'#00ff00',
          display:['-webkit-box', '-ms-flexbox','flex']
        },
        classObj:{
          isActive:true,
          backgroundColor:'#00ff00'
        },
        isActive:true,
        pp:'',
        attrName:'href',
        eventName:'click',
        urlData:'www.baidu.com',
        userInput:"hellp mfk",
        message:"hello vaue",
        isTrue:false,
        todos:[
          {text:'key01',value:'value01',id:'id1'},
          {text:'key02',value:'value02',id:'id2'},
        ],
        msg:'so what',
        clickCount:0
      }
    },
    methods: {
      whenOnClick:function(msg,event){
        console.log('when on click')

      },
      whenOnFocus(){
        console.log('when on focus')
      },
      mfk (){
        this.clickCount=this.clickCount+1;
        this.message=this.msg+' mother fucker *'+this.clickCount ;
      }
    },
    computed:{
      classObjByCal:function (){
        return {
          ok:'isoker'
        }
      },
      reversedMessage:{
        set:function () {
          this.message=this.message+':'+Date.now();
        },
        get:function () {
          return this.message.split('').reverse().join('')+' :'+Date.now()
        }
      },
      // reversedMessage:function () {
      //     return this.message.split('').reverse().join('')+' :'+Date.now()
      // }
    },
    watch:{
      pp:function (newValue,oldValue) {
        console.log('new value :'+newValue)
        console.log('old value :'+oldValue)
      }
    }
  }
</script>
