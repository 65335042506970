<template>
  <div class="container">
    <div @click="showSelect = true">
      <van-nav-bar>
        <template #title>
          <div class="headTitle">
            <h1>{{ modelName }}</h1>
            <span>{{ $t('lang.deviceType') }}</span>
          </div>
        </template>
        <template #right>
          <van-icon name="arrow" color="#252A33" />
        </template>
      </van-nav-bar>
    </div>
    <van-sticky>
      <van-tabs v-model="showTab" :color="themeColor" :title-active-color="themeColor" title-inactive-color="#222222"
        :ellipsis="false" @click="changeTabs">
        <van-tab :title="item" v-for="(item, index) in tabs" :key="index"></van-tab>
      </van-tabs>
    </van-sticky>
    <div class="tabContent" v-if="!showVideo && !networkError">
      <div v-if="showTab === 0">
        <div v-for="(item, index) in instructionsObj" :key="index">
          <p class="title">{{ item.name }}</p>
          <van-grid :border="false" :column-num="2" :gutter="16">
            <van-grid-item v-for="(itemL, indexL) in item.children" :key="indexL" @click="openVideo(itemL.fileUrl)">
              <div class="Box">
                <span class="van-multi-ellipsis--l2 itemTitle">{{ itemL.name }}</span>
                <div class="foot">
                  <van-icon name="play-circle" :color="themeColor" />
                  <!-- <van-image width="2.5rem" height="2.5rem" :src="open" /> -->
                </div>
              </div>
            </van-grid-item>
          </van-grid>
        </div>
        <div class="tip">{{ $t('lang.continuousUpdate') }}</div>
      </div>
      <div v-if="showTab === 1">
        <div class="videoBox" v-for="(item, index) in hardwareVideo" :key="index" @click="openVideo(item.fileUrl)">
          <span>{{ item.name }}</span>
          <van-icon name="play-circle" :color="themeColor" size="2.5rem" />
          <!-- <van-image width="2.5rem" height="2.5rem" :src="open" /> -->
        </div>
        <div class="instructions" v-if="hardwareImg.length !== 0">
          <p class="title">{{ $t('lang.manual') }}</p>
          <img :src="item.fileUrl" style="width:100%;display:block" v-for="(item, index) in hardwareImg" :key="index" />
        </div>
      </div>
      <div v-if="showTab === 2">
        <van-collapse v-model="faqName" accordion>
          <van-collapse-item :title="item.name" :name="item.name" v-for="(item, index) in faqList" :key="index"
            title-class="faqTitle">
            <img :src="item.fileUrl" style="width:100%;display:block" />
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
    <div class="loadErrorBox" v-if="networkError">
      <img :src="networkErr" class="networkImg">
      <div class="tipBox">
        <span>{{ $t('lang.networkAnomaly') }}</span>
        <img :src="refresh" @click="getDatas">
      </div>
    </div>
    <div class="videoBox" v-if="showVideo">
      <div class="btnBox">
        <!-- <van-button color="#FF0000" icon="cross" style="width:3.5rem;height:2rem;border-radius:0.4rem"
          @click="showVideo = false"></van-button> -->
        <van-icon name="clear" color="#ee0a24" @click="showVideo = false" size="2rem" />
      </div>
      <video width="80%" controls autoplay style="display:block;margin: 0 auto;">
        <source :src="videoUrl" type="video/mp4">
      </video>
    </div>
    <van-popup v-model="showSelect" position="bottom">
      <div class="popupTitle">
        <div class="btnBox">
          <img :src="close" @click="showSelect = false" style="width:100%">
        </div>
        <div class="title">{{ $t('lang.deviceType') }}</div>
        <div class="btnBox">
          <img :src="sure" @click="onConfirm" style="width:100%">
        </div>
      </div>
      <van-picker ref="pickerRef" :columns="columns" @change="changeSelect" />
    </van-popup>
    <load v-show="showLoad"></load>
  </div>
</template>

<script>
import open from '@/assets/open.png'
import networkErr from '@/assets/networkErr.png'
import refresh from '@/assets/refresh.png'
import close from '@/assets/close.png'
import sure from '@/assets/sure.png'
import load from '../../load'
import { getTypeList, getType, getDatas } from '@/api/tutorial'
export default {
  components: { load },
  data() {
    return {
      modelName: 'M110',
      showSelect: false,
      columns: [],
      selectValue: '',
      tabs: ['软件使用说明', '硬件使用说明', '常见问题'],
      showTab: 0,
      instructionsObj: [],
      hardwareVideo: [],
      hardwareImg: [],
      open,
      close,
      sure,
      networkErr,
      refresh,
      faqName: '1',
      faqList: [],
      showVideo: false,
      videoUrl: '',
      showLoad: false,
      networkError: false,
      themeColor: '#6CC8F5'
    }
  },
  methods: {
    // 获取设备系列
    async getTypeList() {
      this.showLoad = true;
      await getTypeList().then(res => {
        this.columns = res.data;
      })
    },
    // 根据sn获取设备系列
    async getType(params) {
      await getType({ sn: params.sn || '' }).then(res => {
        this.showLoad = false;
        this.$nextTick(() => {
          this.modelName = res.data || this.modelName;
          this.getDatas(params.isNew);
        })
      })
    },
    // 获取视频图片素材
    getDatas(isNew) {
      this.showLoad = true;
      const params = {
        type: this.modelName,
        bizType: this.showTab === 0 ? 'SOFTWARE' : this.showTab === 1 ? 'HARDWARE' : 'FAQ',
        lang: this.$route.query.lang || 'zh-cn',
        isNew: isNew || ''
      }
      getDatas(params).then(res => {
        if (res.status !== 2000) {
          this.showLoad = false;
          this.networkError = true;
          return;
        };
        switch (this.showTab) {
          case 0:
            this.instructionsObj = res.data;
            break;
          case 1:
            this.hardwareVideo.length = 0;
            this.hardwareImg.length = 0;
            res.data.forEach(item => {
              if (item.fileType === 1) {
                this.hardwareVideo.push(item);
              } else {
                this.hardwareImg.push(item);
              }
            });
          case 2:
            this.faqList = res.data;
            this.faqName = res.data.length === 0 ? '' : res.data[0].name;
            break;
        };
        this.networkError = false;
        this.showLoad = false;
      }).catch(err => {
        this.showLoad = false;
        this.networkError = true;
      })
    },
    changeTabs() {
      this.getDatas();
      this.showVideo = false;
    },
    changeSelect(Picker, value, index) {
      console.log(value, '11');
      this.selectValue = value;
    },
    onConfirm() {
      this.modelName = this.selectValue || this.columns[0];
      this.showSelect = false;
      this.getDatas();
    },
    // 打开对应视频
    openVideo(url) {
      this.videoUrl = url;
      this.showVideo = true;
    },
    // 设置tabs语言
    tabsLang() {
      this.tabs.length = 0;
      this.tabs.push(this.$i18n.t('lang.softwareTotalDetail'));
      this.tabs.push(this.$i18n.t('lang.hardwareTotalDetail'));
      this.tabs.push(this.$i18n.t('lang.faq'));
    }
  },
  created() {
    let lang = this.$route.query.lang;
    if (lang === undefined || lang === null || lang === '') {
      this.$i18n.locale = 'zh-cn';
    } else {
      this.$i18n.locale = lang;
      this.tabsLang();
    };
    let isNew = this.$route.query.isNew;
    if (!!isNew && isNew === '1') {
      this.themeColor = '#E45040'
    }
    this.getTypeList();
    this.getType({ sn: this.$route.query.sn, isNew: this.$route.query.isNew });
  }
}
</script>

<style scoped>
div /deep/ .van-nav-bar {
  min-height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

div /deep/ .van-nav-bar__content {
  flex: 1;
}

div /deep/ .van-nav-bar__right {
  font-size: 1.5rem;
}

div /deep/.van-grid-item {
  border-radius: 0.5rem;
  margin-top: 0.5rem;
}

div /deep/ .van-grid-item__content {
  padding: 0.8rem;
  border-radius: 0.5rem;
}

div /deep/ .van-grid-item__content--center {
  align-items: stretch;
  -webkit-align-items: stretch;
}

.headTitle {
  text-align: center;
  line-height: 1.5rem;
  color: #252A33;
}

.headTitle h1 {
  margin: 0;
}

.headTitle span {
  color: #7D8799;
  font-weight: bold;
  font-size: 1rem;
  font-family: PingFang SC;
}

.headTitle h1 {
  font-size: 1.3rem;
}

.popupTitle {
  height: 2.5rem;
  background: #F4F4F4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.popupTitle .title {
  color: #000;
  font-weight: bold;
  font-size: 1.2rem;
}

.popupTitle .btnBox {
  width: 3rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
}

.tabContent {
  margin-top: 1rem;
}

.tabContent .title {
  margin: 1.5rem 1rem 0 1rem;
  color: #7D8799;
  font-weight: 400;
  font-family: PingFang SC;
}

.tabContent .itemTitle {
  height: 2.625rem;
  color: #252A33;
}

.tabContent .instructions .title {
  margin: 1rem;
  color: #7D8799;
  font-weight: 400;
  font-family: PingFang SC;
}

.tabContent .foot {
  margin-top: 0.5rem;
  height: 40px;
  text-align: right;
  font-size: 2.5rem;
}

.tabContent .videoBox {
  border-radius: 0.5rem;
  padding: 1rem;
  background: #fff;
  margin: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #252A33;
  font-weight: 400;
}

.tabContent .tip {
  color: #7D8799;
  text-align: center;
  margin: 1rem 0;
  font-size: 1rem;
}

div /deep/ .faqTitle {
  font-weight: bold;
  color: #252A33;
}

.videoBox {
  margin-top: 1rem;
}

.btnBox {
  text-align: right;
  margin: 1rem;
}

.loadErrorBox {
  text-align: center;
  height: calc(100vh - 8.75rem);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #fff;
}

.loadErrorBox .networkImg {
  width: 10rem;
  height: 10rem;
}

.loadErrorBox .tipBox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadErrorBox .tipBox span {
  color: #7D8799;
  font-weight: 400;
  margin: 0 0.5rem;
}

.loadErrorBox .tipBox img {
  width: 1rem;
}
</style>
