<!--
@create date 2021-10-23
@desc 小标打印机说明书列表
-->

<template>
  <div class="instructionsList">
    <h3>选择打印机后查看说明书</h3>
    <p>Select the printer and view instruction</p>
    <van-row class="listContainer">
      <van-col span="12" class="listItem" v-for="(item, index) in listData" :key="index">
        <img :src="item.iconUrl || defaultImg" @click="turnToDetail(item)" />
        <p>
          <span @click="turnToDetail(item)">{{ item.deviceType }}</span>
        </p>
      </van-col>
    </van-row>
    <load v-show="loading"></load>
  </div>
</template>

<script>
import { getDevices } from "@/api/instructions";
import Load from "@/components/load.vue";
import defaultImg from "@/assets/notImg.png";

export default {
  name: "Instructions",
  data() {
    return {
      defaultImg,
      loading: false,
      listData: []
    };
  },
  components: { Load },
  mounted() {
    document.title = 'instructions';
    this.initList();
  },
  methods: {
    getUrlParam() {
      const { hash } = location;
      if (hash) {
        const str1 = hash.split("?")[1] || "";
        const paramArr = str1.split("&");
        const params = {};
        paramArr.forEach(v => {
          if (v) {
            const item = v.split("=");
            if (item && item.length > 0 && item[0]) {
              params[item[0]] = item[1];
            }
          }
        });
        return params;
      } else return {};
    },
    initList() {
      this.loading = true;
      getDevices(this.getUrlParam())
        .then(res => {
          if (res && res.status === 2000) {
            this.listData = res.data || [];
          }
        })
        .finally(err => {
          this.loading = false;
        });
    },
    turnToDetail(item = {}) {
      if (item.id) location.hash = `/instructDetail/${item.deviceType}/${item.id}`;
    }
  }
};
</script>

<style src="./style.css" />
